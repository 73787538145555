import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { node, object, string } from 'prop-types'
import Newsletter from '../newsletter'
import Hero from './hero'
import { Description, FormWrapper, HeroStyled, Title } from './hero-newsletter.styled'
import { Parallax } from 'react-scroll-parallax'

const HeroNewsletter = ({
	title,
	titleProps,
	description,
	descriptionProps,
	variant,
	action,
	inputProps,
	checkboxProps,
	buttonProps,
	iconButtonProps,
	...props
}) => {
	const {
		newsletter: { enabled: newsletterEnabled },
	} = useConfig()

	return newsletterEnabled ? (
		<HeroStyled
			overlayComponent={
				<>
					{title && (
						<Parallax easing="easeOut" translateX={[5, 0]}>
							<Title variant="h1" {...titleProps}>
								{title}
							</Title>
						</Parallax>
					)}
					{description && (
						<Parallax easing="easeOut" translateX={[5, 0]}>
							<Description component="div" variant="subheadline2" {...descriptionProps}>
								{description}
							</Description>
						</Parallax>
					)}
					<Parallax easing="easeOut" translateX={[5, 0]}>
						<FormWrapper>
							<Newsletter
								source="Banner"
								variant={variant}
								action={action}
								checkboxProps={checkboxProps}
								inputProps={inputProps}
								buttonProps={buttonProps}
								iconButtonProps={iconButtonProps}
							/>
						</FormWrapper>
					</Parallax>
				</>
			}
			{...props}
		/>
	) : null
}

HeroNewsletter.defaultProps = {
	...Hero.defaultProps,
	verticalAlign: 'center',
	horizontalAlign: 'center',
	variant: 'standard',
	action: 'dialog',
}

HeroNewsletter.propTypes = {
	...Hero.propTypes,
	title: node,
	titleProps: object,
	description: node,
	descriptionProps: object,
	action: string,
	inputProps: object,
	buttonProps: object,
	checkboxProps: object,
}

export default HeroNewsletter
